
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import DataTable from '@/components/DataTable/index.vue'
import moment from 'moment'
import PacingChart from '@/components/PacingChart/PacingChart.vue'
import LineItem from '@/models/LineItem'
import { clone } from 'lodash'
import Order from '@/models/Order'
import AuditHistory from '@/components/AuditHistory/AuditHistoryTable.vue'
import Issue from '@/models/Issue'
import WebMessage from '@/models/WebMessage'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import FrequencyCaps from '@/components/FrequencyCaps/FrequencyCaps.vue'
import RowToggle from '@/components/RowToggle/RowToggle.vue'
import DistributionGoalGroup from '@/pages/Sales/Packages/components/DistributionGoalGroup.vue'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import InspectTargetAll from '@/components/InspectTarget/all.vue'
import EventTracker from '@/components/EventTracker/EventTracker.vue'
import TimeRestriction from './TimeRestriction.vue'
import lineitem_options from '../line-items-options'
import lineItemsfields from '../line-items-table'
import InventoryTargetting from './Targetting/InventoryTargetting.vue'
import LineItemCreative from './LineItemCreative.vue'
import BatchLineItem from './BatchLineItem.vue'

@Component({
  components: {
    Widget,
    IconAction,
    FormInput,
    CompanyPicker,
    SelectPicker,
    SearchInput,
    DataTable,
    PacingChart,
    AuditHistory,
    DatePicker,
    TimeRestriction,
    FrequencyCaps,
    RowToggle,
    DistributionGoalGroup,
    InventoryTargetting,
    LineItemCreative,
    MediaPackagePicker,
    InspectTargetAll,
    EventTracker,
    BatchLineItem,
  },
})
export default class LineItemsTable extends ViewModel {
  @Ref() readonly audit_history!: any

  @Ref() readonly validator!: any

  @Ref() readonly searchInput!: SearchInput

  @Ref() readonly dataTable!: DataTable

  @Ref() readonly batchLineItemCopy!: any

  @Prop()
  public order!: Order

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public query: string[] = ['is_not:archived', 'adserver:cim']

  public fieldFilters: any = {
    start_at: '',
    end_at: '',
    status: '',
    type: '',
    priority: '',
    pacing: '',
  }

  public ready: boolean = false

  public preview_targetting: boolean = false

  public show_filter_helper: boolean = false

  public fields: any = lineItemsfields

  public clipboard: string = ''

  // Selected rows
  public selected: string[] = []

  public items: LineItem[] = []

  public selected_to_update: string[] = []

  public modal: any = {
    history: false,
    batchUpdate: false,
  }

  public temp_batch_update: LineItem = new LineItem()

  public temp_preview: LineItem = new LineItem()

  public query_settings: any = {
    user_fields: [
      {
        name: 'user',
        key: 'user',
        description: 'Show all line items that are assigned to a user',
      },
    ],
    custom_fields: [
      {
        name: 'is_not:archived',
        value: 'is_not:archived',
        description: 'Show all line items that are not archived',
      },
      {
        name: 'status:delivering',
        value: 'status:delivering',
        description: 'Show all line items that are currently delivering',
      },
      {
        name: 'status:ready',
        value: 'status:ready',
        description: 'Show all line items that are ready to deliver',
      },
      {
        name: 'status:paused',
        value: 'status:paused',
        description: 'Show all line items that are paused',
      },
      {
        name: 'status:completed',
        value: 'status:completed',
        description: 'Show all line items that are completed',
      },
      {
        name: 'status:missing_creative',
        value: 'status:missing_creative',
        description: 'Show all line items that are missing creative',
      },
      {
        name: 'is:starting_soon',
        value: 'is:starting_soon',
        description: 'Show all line items that are starting soon',
      },
      {
        name: 'is:ending_soon',
        value: 'is:ending_soon',
        description: 'Show all line items that are ending soon',
      },
      {
        name: 'is:open_ended',
        value: 'is:open_ended',
        description: 'Show all line items that are open ended',
      },
      {
        name: 'is_not:open_ended',
        value: 'is_not:open_ended',
        description: 'Show all line items that are not open ended',
      },
      {
        name: 'is:delivered',
        value: 'is:delivered',
        description: 'Show all line items that delivered all booked impressions',
      },
      {
        name: 'is_not:delivered',
        value: 'is_not:delivered',
        description: 'Show all line items that are still have impressions to deliver',
      },
      {
        name: 'adserver:cim',
        value: 'adserver:cim',
        description: 'Show all line items that are using CIM adserver',
      },
      {
        name: 'adserver:gam',
        value: 'adserver:gam',
        description: 'Show all line items that are using GAM adserver',
      },
    ],
    company_fields: [
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
        description: 'Include only the specified advertiser',
      },
      {
        name: 'agency',
        key: 'agency_id',
        type: 'agency',
        description: 'Include only the specified agency',
      },
    ],
  }

  public action_options: any = [
    {
      text: 'Replace',
      value: true,
    },
    {
      text: 'Include',
      value: false,
    },
  ]

  public filter_mode: string = 'exclusive'

  @Watch('query')
  public onQueryChange() {
    if (this.dataTable) {
      this.dataTable.page = this.page
    }
  }

  @Watch('filter_mode')
  public onFilterModeChange() {
    if (this.dataTable) {
      this.dataTable.page = this.page
    }

    if (!this.loading) {
      this.refresh()
    }
  }

  @Watch('selected_to_update')
  public onSelctedToUpdate(value: any) {
    if (
      value.includes('lineItem.media_package_id')
      && (value.includes('lineItem.distribution_goals') || value.includes('lineItem.adunits'))
    ) {
      // remove from this.selected_to_update lineItem.distribution_goals end lineItem.adunits
      this.selected_to_update = this.selected_to_update.filter(
        i => i !== 'lineItem.distribution_goals' && i !== 'lineItem.adunits',
      )
    }
  }

  public get selected_line_items() {
    if (!this.selected.length) {
      return []
    }
    return this.items.filter((item: any) => this.selected.includes(item.id))
  }

  public get disabled_distribution_goals() {
    if (this.isEnabled('lineItem.distribution_goals')) {
      return (
        !this.temp_batch_update.targetting.include_adunits.length
        && !this.temp_batch_update.targetting.exclude_adunits.length
      )
    }

    return true
  }

  public get show_fields() {
    return this.fields.filter(i => i.show)
  }

  public get chartDates() {
    let ret = []
    let date = moment().subtract(7, 'days')
    for (let i = 0; i < 7; i++) {
      ret.push(date.add(1, 'days').format('MMM, Do YYYY'))
    }

    return ret
  }

  public get line_item_options() {
    return lineitem_options
  }

  public get masks() {
    return { percentageMask, currencyMask }
  }

  public mounted() {
    const { query } = this.$route

    let filterUpdated = false
    if (query.advertiser) {
      this.query.push(`advertiser_id:${query.advertiser}`)
      filterUpdated = true
    }

    if (query.agency) {
      this.query.push(`agency_id:${query.agency}`)
      filterUpdated = true
    }

    if (query.status) {
      this.query.push(`status:${query.status}`)
      filterUpdated = true

      return
    }

    if (filterUpdated) {
      this.ready = true
      this.syncFilters()
    }

    if (query.pre_load && query.pre_load.length) {
      this.ready = false

      let ids: any = query.pre_load.split(',')

      ids.forEach((id: string) => {
        this.query.push(`id:${id}`)
      })
      this.syncFilters()
      this.ready = true
      this.$router.replace({ query: undefined })
      return
    }

    this.loadFilters()
  }

  public isEnabled(name: string) {
    return this.selected_to_update.includes(name)
  }

  public rows(ctx: any) {
    this.loading = true

    // just in case we have unique_inclusive in the query
    // this.query = this.query.filter(i => !i.startsWith('unique_inclusive'))

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '' && this.fieldFilters[key] !== null)
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    let q = [...ctx.filter, ...field_filters]

    if (this.$route.params.id) {
      let order_id = `order_id:${this.$route.params.id}`
      q = [...q, order_id]
    }

    if (this.$route.query.unique_inclusive && this.$route.query.unique_inclusive.length) {
      let unique_inclusive: any = this.$route.query.unique_inclusive
      if (Array.isArray(unique_inclusive)) {
        if (unique_inclusive.length) {
          unique_inclusive = unique_inclusive.map((i: string) => `unique_inclusive:${i}`)
          q = [...q, ...unique_inclusive]
        }
      } else {
        q = [...q, `unique_inclusive:${unique_inclusive}`]
      }
    }

    return LineItem.paginate({
      page_size: ctx.perPage,
      page: ctx.currentPage,
      order_by: ctx.sortBy,
      order: ctx.sortDesc ? 'desc' : 'asc',
      query: q,
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.items = result.data
      this.selected = []
      this.loading = false
      this.ready = true
      return result.data
    })
  }

  public resetFilters() {
    Vue.set(this, 'fieldFilters', {
      start_at: '',
      end_at: '',
      status: '',
      type: '',
      priority: '',
      pacing: '',
    })
    this.query = ['is_not:archived', 'adserver:cim']
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.refresh()
  }

  public syncFilters() {
    let type = 'line-items'
    if (this.$route.params.id) {
      type += `-order_${this.$route.params.id}`
    }
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type,
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    let type = 'line-items'
    if (this.$route.params.id) {
      type += `-order_${this.$route.params.id}`
    }

    const system = getModule(SystemtModule)
    system.getFilter(type).then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    let type = 'line-items'
    if (this.$route.params.id) {
      type += `-order_${this.$route.params.id}`
    }

    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type,
      data: null,
    })
  }

  public refresh(tm: number = 0) {
    setTimeout(() => {
      this.dataTable.refresh()
    }, tm)
  }

  public viewChildren(type: string, name: string, id: string) {
    const q = clone(this.query)

    if (q.includes(`${type}:${id}`)) {
      return
    }
    q.push(`${type}:${id}`)
    let type_name = type.replace('_id', '')
    this.searchInput.addCustomTag({
      name: `${type_name}:${name}`,
      value: `${type}:${id}`,
    })
    this.query = q

    this.refresh(300)
  }

  public viewHistory(line_item: LineItem) {
    this.modal.history = true

    this.audit_history.viewHistory(line_item)
  }

  public bulkStatusChange(status: any) {
    if (this.selected.length === 0) {
      WebMessage.error('Please select at least one line item')
      return
    }

    this.loading = true
    LineItem.bulkStatusChange(this.selected, status).then(() => {
      this.refresh()
      this.selected = []
      this.loading = false
    })
  }

  public dismissIssue(issue: any, line_item: LineItem) {
    WebMessage.confirm(
      `<strong>${line_item.name}</strong>. <br/> <br/>Do you want to dismiss this issue? <br/> <br/>
      ${issue.message.replaceAll(',', '')}`,
      'Issue',
      {
        okTitle: 'Dismiss',
      },
    ).then((res: boolean) => {
      if (res) {
        Issue.dismissIssue(issue.id).then(() => {
          WebMessage.success('Issue dismissed')
          this.refresh()
        })
      }
    })
  }

  public batchUpdate() {
    this.loading = true

    WebMessage.success(`Updating ${this.selected.length} Line items`)

    this.temp_batch_update
      .batchUpdate(this.selected, this.selected_to_update)
      .then(res => {
        WebMessage.success('Line items updated')
        this.loading = false
        this.resetTempBatch()
        this.refresh(200)
      })
      .catch(() => {
        this.loading = false
      })
  }

  public resetTempBatch() {
    this.modal.batchUpdate = false
    this.temp_batch_update = new LineItem()
    this.selected_to_update = []
  }

  public newBatch() {
    if (this.selected.length === 0) {
      WebMessage.error('Please select at least one line item')
      return
    }
    this.selected_to_update = []
    this.temp_batch_update = new LineItem()

    let selected = this.items.filter(i => this.selected.includes(i.id))

    let advertiser_ids = selected.map((item: LineItem) => item.advertiser_id)

    advertiser_ids = [...new Set(advertiser_ids)]

    // @ts-ignore
    this.temp_batch_update.advertiser_id = advertiser_ids

    this.temp_batch_update.buffer_rule = 'percentage'
    this.modal.batchUpdate = true
  }

  public useRuleWhenSelected(key: string, rules: any) {
    if (this.isEnabled(key)) {
      return rules
    }
    return ''
  }

  public copy(val: string) {
    this.clipboard = val
    // @ts-ignore
    this.$refs.clipboard.setAttribute('type', 'text')

    setTimeout(() => {
      // @ts-ignore
      this.$refs.clipboard.select()

      let status = false

      try {
        status = document.execCommand('copy')
      } catch (e) {
        status = false
      }

      // @ts-ignore
      this.$refs.clipboard.setAttribute('type', 'hidden')

      if (status) {
        WebMessage.success('ID was copied to your clipboard!')
      } else {
        WebMessage.error(
          `Ops! We were not able to copy to your clipboard, please copy: ${this.clipboard}`,
        )
      }
    }, 100)
  }

  public previewTarget(d: any) {
    this.temp_preview = d

    setTimeout(() => {
      this.preview_targetting = true
    }, 200)
  }

  public batchCreateCopy() {
    this.batchLineItemCopy.open()
  }

  public loadIds(ids: string[]) {
    this.ready = false

    this.loading = true

    // remove from this.query, everything that start with id:
    this.query = this.query.filter(i => !i.startsWith('id:'))

    ids = ids.map(i => `id:${i}`)

    Vue.set(this, 'query', [...new Set([...this.query, ...ids])])

    this.$router.push({ query: { unique_inclusive: ['id'] } })

    this.searchInput.inclusive = true

    this.syncFilters()

    this.loadFilters()

    this.selected = []
  }
}
